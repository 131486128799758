import { useTranslation } from "react-i18next";
import Pie from "../charts/Pie";
import { useState } from "react";
import { Loader } from "../../template";
import { RiFileExcelLine } from "react-icons/ri";

import * as XLSX from "xlsx";
import { format, parseISO } from "date-fns";
import { mainApi } from "../../providers/api";
import { DEBUG } from "../../defines";
import Meta from "./Meta";

const AdminView = (props) => {
	const { id, results, roles, complated } = props;

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);

	const onClickDownload = async () => {
		try {
			setLoading(true);
			const response = await mainApi(`/survey/export/${id}`);

			await exportToExcel(
				"Survey " + format(new Date(), "yyyy-MM-dd HH:mm:ss"),
				{
					sheets: response.data.sheets,
					responses: response.data.responses.reduce((prev, cur) => {
						prev[cur.user] = {
							answers: cur.answers,
							responded: format(parseISO(cur.created), "yyyy-MM-dd HH:mm:ss"),
						};
						return prev;
					}, {}),
				}
			);
		} catch (error) {
			DEBUG && console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const exportToExcel = (filename, { sheets, responses }) => {
		const header = [
			"Анги",
			"Хэрэглэгчийн код",
			"Хандах эрх",
			"Нэр",
			"Хариулсан огноо",
			...results.map(({ question }) => question),
		];

		const data = [];
		sheets.map((sheet) => {
			if (roles.includes("student"))
				sheet.students.map((s) => {
					const class_name = sheet.class;
					const code = s.code;
					const role = "Сурагч";
					const name = s.firstname;
					const responded = responses[s.id]?.responded;
					const answers = responses[s.id]?.answers;

					data.push([
						class_name,
						code,
						role,
						name,
						responded,
						...(answers
							? answers.map(({ response }) =>
									Array.isArray(response) ? response.join(", ") : response
							  )
							: []),
					]);
				});

			if (roles.includes("parent"))
				sheet.parents.map((p) => {
					const class_name = sheet.class;
					const code = p.code;
					const role = "Асран хамгаалагч";
					const name = `${p.firstname} /${p.student}-н ${t(
						`const.relation.${p.relation}`,
						{ lng: "MN" }
					)}/`;
					const responded = responses[p.id]?.responded;
					const answers = responses[p.id]?.answers;

					data.push([
						class_name,
						code,
						role,
						name,
						responded,
						...(answers
							? answers.map(({ response }) =>
									Array.isArray(response) ? response.join(", ") : response
							  )
							: []),
					]);
				});
		});

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet([header, ...data], {
			skipHeader: true,
		});

		ws["!cols"] = header.map(() => ({
			wch: 30,
		}));

		XLSX.utils.book_append_sheet(wb, ws);

		return XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	return (
		<div className="p-5 bg-white relative">
			{loading && <Loader></Loader>}
			<Meta {...props}></Meta>
			<div className="mb-5">Судалгаанд хамрагдсан тоо: {complated}</div>
			{results?.map(({ type, options, question, total }, index) => (
				<div key={index} className="my-2 pb-2 border-b">
					<div>
						{index + 1}. {question}
					</div>
					<div className="text-sm italic">Хариулт өгсөн: {total}</div>

					{["multiple_choices", "single_choice"].includes(type) && (
						<Pie
							height={300}
							data={options}
							nameKey="option"
							dataKey="total"
							renderLabel={({ payload }) =>
								`${payload.option}: ${payload.total}`
							}
						/>
					)}
				</div>
			))}
			<div className="flex justify-end mt-4">
				<div>
					<button
						type="button"
						onClick={() => {
							onClickDownload();
						}}
						className="button gray"
					>
						XLSX <RiFileExcelLine className="text-lg ml-1" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdminView;
