import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import "./providers/i18n";

import AppProvider from "./providers/app";

import Loader from "./pages/Loader";

import Home from "./pages/Home0.2";
import Employees from "./pages/Employees";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Contact from "./pages/Contact";
import School from "./pages/School";
import Campus from "./pages/Campus";
import Report from "./pages/Report";

import Dashboard from "./dashboard/Index";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import PublicLayout from "./layouts/Public";
import AppLayout from "./layouts/App";
import AuthLayout from "./layouts/Auth";
import Settings from "./pages/Settings";
import axios from "axios";
import { API_ROOT } from "./defines";

import ClassList from "./components/class/List";
import ClassForm from "./components/class/Form";
import EmployeeList from "./components/user/EmployeeList";
import EmployeeForm from "./components/user/EmployeeForm";
import StudentList from "./components/user/StudentList";
import StudentForm from "./components/user/StudentForm";
import ParentForm from "./components/user/ParentForm";
import ArticleList from "./components/article/List";
import ArticleForm from "./components/article/Form";
import ContentList from "./components/content/List";
import ContentForm from "./components/content/Form";
import AnnouncementList from "./components/announcement/List";
import AnnouncementForm from "./components/announcement/Form";
import FeedbackList from "./components/feedback/List";
import FeedbackForm from "./components/feedback/Form";
import PollList from "./components/poll/List";
import PollForm from "./components/poll/Form";
import TaskList from "./components/task/List";
import TaskForm from "./components/task/Form";
import Search from "./components/user/Search";

import ReportList from "./components/report/List";
import ReportForm from "./components/report/Form";

import EnrollmentList from "./components/enrollment/List";
import EnrollmentForm from "./components/enrollment/Form";

import SurveyTemplateList from "./components/survey-template/List";
import SurveyTemplateForm from "./components/survey-template/Form";

import SurveyForm from "./components/survey/Form";
import SurveyList from "./components/survey/List";

import Join from "./pages/Join";
import Privacy from "./pages/Privacy";

axios.defaults.baseURL = API_ROOT;
document.addEventListener("wheel", function () {
	if (document.activeElement.type === "number") {
		document.activeElement.blur();
	}
});

const root = createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<React.StrictMode>
			<Suspense fallback={<Loader />}>
				<AppProvider>
					<div className="h-full w-full flex">
						<Routes>
							<Route path="/" element={<Navigate to="/public/home" />} />
							<Route path="public" element={<PublicLayout />}>
								<Route path="home" element={<Home />} />
								<Route path="employee" element={<Employees />} />
								<Route path="article" element={<Articles />} />
								<Route path="article/:id" element={<Article />} />
								<Route path="contact" element={<Contact />} />
								<Route path="school" element={<School />} />
								<Route path="campus" element={<Campus />} />
								<Route
									path="join"
									element={
										<Join
											enrollment={
												Date.now() > new Date("2024-08-16 00:00:00") &&
												Date.now() < new Date("2024-08-22 00:00:00")
											}
										/>
									}
								/>
								<Route path="join-test" element={<Join enrollment={true} />} />
								<Route path="privacy" element={<Privacy />} />
								<Route path="report" element={<Report />} />
							</Route>
							<Route path="auth" element={<AuthLayout />}>
								<Route path="login" element={<Login />} />
								<Route path="logout" element={<Login from="inside" />} />
							</Route>
							<Route path="app" element={<AppLayout />}>
								<Route path="dashboard" element={<Dashboard />} />

								<Route path="feedback" element={<FeedbackList />} />
								<Route path="feedback/view/:id" element={<FeedbackForm />} />

								<Route path="poll" element={<PollList />} />
								<Route path="poll/new" element={<PollForm />} />
								<Route path="poll/view/:id" element={<PollForm />} />

								<Route path="article" element={<ArticleList />} />
								<Route path="article/new" element={<ArticleForm />} />
								<Route path="article/view/:id" element={<ArticleForm />} />

								<Route path="enrollment" element={<EnrollmentList />} />
								<Route
									path="enrollment/view/:id"
									element={<EnrollmentForm />}
								/>

								<Route
									path="survey-template"
									element={<SurveyTemplateList />}
								/>
								<Route
									path="survey-template/new"
									element={<SurveyTemplateForm />}
								/>
								<Route
									path="survey-template/view/:id"
									element={<SurveyTemplateForm />}
								/>

								<Route path="survey" element={<SurveyList />} />
								<Route path="survey/new" element={<SurveyForm />} />
								<Route path="survey/view/:id" element={<SurveyForm />} />

								<Route path="report" element={<ReportList />} />
								<Route path="report/new" element={<ReportForm />} />
								<Route path="report/view/:id" element={<ReportForm />} />

								<Route path="book" element={<ContentList type="book" />} />
								<Route path="book/new" element={<ContentForm type="book" />} />
								<Route
									path="book/view/:id"
									element={<ContentForm type="book" />}
								/>

								<Route path="lesson" element={<ContentList type="lesson" />} />
								<Route
									path="lesson/new"
									element={<ContentForm type="lesson" />}
								/>
								<Route
									path="lesson/view/:id"
									element={<ContentForm type="lesson" />}
								/>

								<Route path="employee" element={<EmployeeList />} />
								<Route path="employee/new" element={<EmployeeForm />} />
								<Route path="employee/view/:id" element={<EmployeeForm />} />

								<Route path="class" element={<ClassList />} />
								<Route path="class/view/:class_id" element={<ClassForm />} />
								<Route path="class/new" element={<ClassForm />} />
								<Route
									path="class/view/:class_id/student"
									element={<StudentList />}
								/>
								<Route
									path="class/view/:class_id/student/add"
									element={<StudentForm />}
								/>
								<Route
									path="class/view/:class_id/student/view/:id"
									element={<StudentForm />}
								/>
								<Route
									path="class/view/:class_id/student/view/:student_id/parent/add"
									element={<ParentForm />}
								/>
								<Route
									path="class/view/:class_id/student/view/:student_id/parent/view/:id"
									element={<ParentForm />}
								/>

								<Route path="announcement" element={<AnnouncementList />} />
								<Route path="announcement/new" element={<AnnouncementForm />} />
								<Route
									path="announcement/view/:id"
									element={<AnnouncementForm />}
								/>

								<Route path="task" element={<TaskList />} />
								<Route path="task/new" element={<TaskForm />} />
								<Route path="task/view/:id" element={<TaskForm />} />

								<Route path="search" element={<Search />} />
								<Route path="settings" element={<Settings type="settings" />} />
							</Route>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</div>
				</AppProvider>
			</Suspense>
		</React.StrictMode>
	</BrowserRouter>
);
