import { useTranslation } from "react-i18next";
import AppMockup from "../components/landing/AppMockup";
import Intro from "../components/landing/Intro";
import RecentArticles from "../components/article/Recent";
import Stats from "../components/landing/Stats";
import FeaturedEmployees from "../components/employee/Featured";
import Directory from "../components/landing/Directory";
import EnrollmentBanner from "../components/enrollment/Banner";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div>
			<RecentArticles></RecentArticles>
			{/* <Stats></Stats> */}
			<EnrollmentBanner></EnrollmentBanner>
			<FeaturedEmployees></FeaturedEmployees>
			<Intro></Intro>
			<Directory></Directory>
			<AppMockup></AppMockup>
		</div>
	);
};

export default Home;
